import http from "./httpService"
import { httpHeaders } from "../utils/functions"
import { toast } from "react-toastify"

export async function getItems(collectionName, filters) {
  const userEndpoint = process.env.API_URL + collectionName
  let url = userEndpoint
  if (filters) {
    const { currentPage, searchQuery } = filters
    const { path, order } = filters.sortColumn
    let sort = path
    if (order === "desc") {
      sort = "-" + sort
    }
    url =
      userEndpoint +
      "?page=" +
      currentPage +
      "&sort=" +
      sort +
      "&locale=" +
      process.env.LOCALE
    if (searchQuery) {
      url += "&query=" + searchQuery
    }
  }
  try {
    const { data } = await http.get(url, httpHeaders())
    let dataToReturn = { ...data }
    /*
    if (
      typeof dataToReturn === "undefined" ||
      typeof dataToReturn.data === "undefined"
    ) {
      throw new Exception("Dati inconsistenti")
    }
    */
    if (filters) {
      dataToReturn.sortColumn = filters.sortColumn
    }
    return dataToReturn
  } catch (error) {
    toast.error(
      "Errore nel recuperare la lista [" +
        collectionName +
        "]. Ricaricare la pagina o contattare l'assistenza"
    )
    return { data: [] }
  }
}

export async function getProducts(path, query) {
  let userEndpoint = process.env.API_URL + path
  try {
    userEndpoint += "?filter[query]=" + query
    const { data } = await http.get(userEndpoint, httpHeaders())
    let dataToReturn = { ...data }
    return dataToReturn
  } catch (error) {
    toast.error(
      "Errore nel recuperare la lista [" +
        path +
        "]. Ricaricare la pagina o contattare l'assistenza"
    )
    return { data: [] }
  }
}

export async function setItem(path, data) {
  const userEndpoint = process.env.API_URL + path
  try {
    if (data.id > 0) {
      await http.patch(userEndpoint, data, httpHeaders())
    } else {
      await http.post(userEndpoint, data, httpHeaders())
    }
    return true
  } catch (ex) {
    return false
  }
}

export async function postItem(path, data) {
  const userEndpoint = process.env.API_URL + path
  try {
    await http.post(userEndpoint, data, httpHeaders())
    return true
  } catch (ex) {
    toast.error(
      "Errore invio dati al server.Riprovare o contattare l'assistenza"
    )
    return false
  }
}

export async function getItem(path, id) {
  const userEndpoint = process.env.API_URL + path
  try {
    const { data } = await http.get(
      userEndpoint + "/" + id + "?locale=" + process.env.LOCALE,
      httpHeaders()
    )
    //console.log("getItem - data", data);
    return data
  } catch (error) {
    console.log("getItem error", error)
  }
}

export async function deleteItem(path, id) {
  const userEndpoint = process.env.API_URL + path
  try {
    await http.delete(userEndpoint + "/" + id, httpHeaders())
    //  console.log("Data", data);
  } catch (error) {
    console.log("error", error)
  }
}
